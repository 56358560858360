<template>
  <el-form ref="ruleForm" :model="productFormData">
    <div class="flex-between-row margin-right-10">
      <h1 class="sub-module-title" >{{title}}</h1>
       <el-button class="float-right" type="primary" round size="mini" v-if="termContractType === TERM_CONTRACT_TYPE.DELIVERED"  @click="addNewProductLine" :disabled="isAllFieldsDisabled" >
          Add
        </el-button>
    </div>
    <div class="sub-module-block-table">
      <div class="div-product-other-costs-info-body">
        <el-table ref="productTable"  :data="productFormData.products" border >
          <el-table-column label="No." width="50px">
            <template v-slot="scope">
              <h5 class="h5-el-table-col-index">{{scope.$index + 1}}</h5>
            </template>
          </el-table-column>
          <el-table-column min-width="300px">
            <template  v-slot:header><span>* </span>Product Name</template>
            <template v-slot="scope">
              <el-form-item :prop="'products.'+scope.$index+'.sysProductId'" :rules="rules.reqRule">
                <el-select
                  v-model="scope.row.sysProductId"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                >
                  <el-option
                    v-for="item in sysProductOptionList"
                    :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="200px" v-if="[TERM_CONTRACT_TYPE.EX_WHARF,TERM_CONTRACT_TYPE.DELIVERED].includes(termContractType)">
            <template v-slot:header><span>* </span>Specs</template>
            <template v-slot="scope">
              <el-form-item :prop="'products.'+scope.$index+'.sysProductSpecificationId'" :rules="rules.reqRule"  >
                <el-select
                  v-model="scope.row.sysProductSpecificationId"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                >
                  <el-option
                    v-for="item in sysPproductSpecsOptionList"
                    :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="150px">
            <template v-slot:header>Min Qty</template>
            <template v-slot="scope">
              <el-form-item  :prop="'products.'+scope.$index+'.minimumQuantity'" :rules="rules.notReqRule">
                <el-input-number
                  :controls="false" :precision="3" :min="null"
                  v-model="scope.row.minimumQuantity"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="Max Qty" min-width="150px">
            <template v-slot="scope">
              <el-form-item :prop="'products.'+scope.$index+'.maximumQuantity'" :rules="rules.notReqRule">
                <el-input-number
                  :controls="false" :precision="3" :min="null"
                  v-model="scope.row.maximumQuantity"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="150px">
            <template v-slot:header><span>* </span>Unit Metric</template>
            <template v-slot="scope">
              <el-form-item :prop="'products.'+scope.$index+'.unitMetric'" :rules="rules.reqRule">
                <el-select
                  v-model="scope.row.unitMetric"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                >
                  <el-option
                    v-for="item in UNIT_OPTIONS.filter(item=>item.label!=='%')"
                    :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="150px">
            <template v-slot:header>Price</template>
            <template v-slot="scope">
              <el-form-item :prop="'products.'+scope.$index+'.price'" :rules="rules.notReqRule">
                <el-input-number
                  :controls="false" :precision="3" :min="null"
                  v-model="scope.row.price"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="Currency" min-width="120px" >
            <template v-slot="scope">
              <el-form-item  :prop="'products.'+scope.$index+'.currency'" :rules="rules.notReqRule">
                <el-select
                  v-model="scope.row.currency"
                  disabled
                >
                  <el-option
                    v-for="item in currencyOptionList"
                    :key="item.id" :label="item.code" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="250px" v-if="termContractType !== TERM_CONTRACT_TYPE.EX_WHARF">
            <template v-slot:header><span>* </span>Physical Supplier BDN</template>
            <template v-slot="scope">
              <el-form-item
              :prop="'products.'+scope.$index+'.physicalSupplierSysOrganizationId'"
              :rules="rules.reqRule">
                <el-select
                  filterable
                  v-model="scope.row.physicalSupplierSysOrganizationId"
                  value-key="id"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                >
                  <!--physicalSupplierList from API -->
                  <el-option
                    v-for="item in physicalSupplierList"
                    :key="item.id" :label="item.companyName" :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="Price Type" min-width="150px" v-if="[TERM_CONTRACT_TYPE.EX_WHARF,TERM_CONTRACT_TYPE.DELIVERED].includes(termContractType)"  >
            <template v-slot="scope">
              <el-form-item :prop="'products.'+scope.$index+'.priceType'"
              :rules="rules.reqRule" >
                <el-select
                  v-model="scope.row.priceType"
                  :disabled="isAllFieldsDisabled || !hasEditPermission"
                  @change="handlePriceTypeSelect($event, scope.row, 'BUYER', scope.$index)"
                >
                  <el-option
                    v-for="item in ORDER_PRICE_TYPE_OPTIONS"
                    :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="" width="90px" fixed="right" v-if="termContractType === TERM_CONTRACT_TYPE.DELIVERED" >
            <template v-slot="scope">
              <el-popconfirm
                confirm-button-text='Delete'
                cancel-button-text='No, Thanks'
                icon="el-icon-info"
                icon-color="red"
                title="Are you sure to delete this?"
                @confirm="removeProduct(scope.$index)"
              >
                <el-button slot="reference" size="small" icon="el-icon-delete" type="danger" circle :disabled="isAllFieldsDisabled || productFormData.products.length === 1"></el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column v-if="[TERM_CONTRACT_TYPE.EX_WHARF,TERM_CONTRACT_TYPE.DELIVERED].includes(termContractType)"    type="expand" min-width="50px" width="auto" fixed="right">
            <template v-slot="scope">
              <table-col-product-pricing
                parent='ProductForm'
                :propsView="view"
                :isAllFieldsDisabled="isAllFieldsDisabled"
                :rulesReq="rules.reqRule"
                :notReqRule="rules.notReqRule"
                :scopeRow="scope.row"
                :scopeIndex="scope.$index"
                :priceType="scope.row.priceType"
                :priceMethodologyList="priceMethodologyList"
                :holidayMap="holidayMap"
                :resetProductPriceWatcher="resetProductPriceWatcher"
                priceProp="products"
                @handleReferencePriceChange="handleReferencePriceChange"
                @handlePriceInfoChange="handlePriceInfoChange"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-form>
</template>
<script>
import { mapState } from 'vuex'
import { UNIT_OPTIONS, ORDER_PRICE_TYPE_OPTIONS, ORDER_PRICE_TYPE, TERM_CONTRACT_TYPE,ORDER_PRICE_RATE_TYPE_OPTIONS } from '@/constants.js'
import TableColProductPricing from '@/views/order/components/TableColProductPricing'
import { getPhysicalSupplierList } from '@/services/modules/comm.js'
export default {
  name: 'ProductInformation',
  components: {
    TableColProductPricing
  },
  props: {
    view: String,
    title: String,
    rules: Object,
    termContractType: String,
    formData: Array,
    isAllFieldsDisabled: Boolean,
    isViewOnly: Boolean,
    isEdit: Boolean,
    hasEditPermission: Boolean
  },
  data () {
    const productPrice = {
      // Price Methodology data
      priceRateType: null,
      priceRateTypeMetric: 'MID',
      priceMethodologyId: null,
      pricePeriod: null,
      priceStartDate: null,
      priceEndDate: null,
      isPriceFactorFirst: false,
      priceConversionRate: 1,
      pricePremium: null,
      priceUnitMetric: 'MT',
      priceRemark: null,
      referencePrice: null

    }
    const productAndPriceLine = {
      sysProductId: null,
      sysProductSpecificationId: null,
      minimumQuantity: null,
      maximumQuantity: null,
      physicalSupplierSysOrganizationId: null,
      unitMetric: 'MT',
      currency: 'USD',
      price: 0,
      priceType: 'FIXED',
      isPriceEndDateDisabled: false,
      ...productPrice
    }
    const initFormData = {
      products: [this.$_.cloneDeep(productAndPriceLine)]
    }
    return {
      // currentCompany: this.$store.state.currentCompany,
      UNIT_OPTIONS,
      ORDER_PRICE_TYPE,
      ORDER_PRICE_TYPE_OPTIONS,
      TERM_CONTRACT_TYPE,
      ORDER_PRICE_RATE_TYPE_OPTIONS,
      productPrice,
      productAndPriceLine,
      productFormData: initFormData,
      currencyOptionList: [],
      sysProductOptionList: [],
      sysPproductSpecsOptionList: [],
      physicalSupplierList: [],
      priceMethodologyList: [],
      holidayMap: new Map(),
      resetProductPriceWatcher: false
    }
  },
  created () {
    /*
      for set holidayMap
    */
    this.setHolidays()
    /*
      for set priceMethodologyList
    */
    this.getAllMethodology()
  },
  mounted () {
    Promise.all([this.getCurrencies(), this.getProducts(), this.getProductSpecifications()]).then(val => {
      this.currencyOptionList = val[0]
      this.sysProductOptionList = val[1]
      this.sysPproductSpecsOptionList = val[2]
    })
    if (this.isEdit && this.formData) {
      this.productFormData.products = this.formData
    }
    this.getPhysicalSupplierList().then(res => {
      if (res?.code === 1000) {
        this.physicalSupplierList = res.data?.records ?? []
      }
    })
  },
  computed: {
    ...mapState(['productSpecificationList','currentCompany']),
  },
  methods: {
    getPhysicalSupplierList,
    /*
      price and formula functions, as same as orderForm
    */
    async getAllMethodology () {
      const getMethodology = (methodologyType) => {
        return new Promise(resolve => {
          this.$request.get({
            url: `/methodologies/companies/${this.currentCompany.id}/bunkerwireType/${methodologyType}`
          }).then(res => {
            if (res?.code === 1000) {
              const methodologies = res.data
              resolve({ methodologies })
            }
          })
        })
      }
      const promises = []
      this.ORDER_PRICE_RATE_TYPE_OPTIONS.forEach(t => {
        promises.push(getMethodology(t.value))
      })
      const methodologyInfoList = await Promise.all(promises)
      methodologyInfoList.forEach(m => {
        m.methodologies?.forEach(i => {
          this.priceMethodologyList.push(i)
        })
      })
    },
    async getCalendarByRange (startDate, endDate) {
      try {
        const res = await this.$request.get({
          url: `${this.$apis.calendar}/range?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        })
        if (res?.code === 1000) {
          return res.data.filter(d => d.fullDay)
        }
      } catch (e) {}
    },
    setHolidays () {
      const currentDate = new Date()
      let startDate = null
      let endDate = null
      if (this.productFormData.products) {
        this.productFormData.products.forEach(p => {
          if (p.priceStartDate && p.priceEndDate) {
            const priceStartDate = new Date(p.priceStartDate)
            const priceEndDate = new Date(p.priceEndDate)
            if (startDate || startDate > priceStartDate) {
              startDate = priceStartDate
            }
            if (endDate || endDate < priceEndDate) {
              endDate = priceEndDate
            }
          }
        })
      }
      if (startDate) {
        startDate = new Date(startDate.getFullYear() - 2, 0, 1)
      } else {
        startDate = new Date(currentDate.getFullYear() - 2, 0, 1)
      }
      if (endDate) {
        endDate = new Date(endDate.getFullYear(), 11, 31)
      } else {
        endDate = new Date(currentDate.getFullYear() + 2, 11, 31)
      }
      this.getCalendarByRange(startDate, endDate).then(data => {
        data.forEach(d => {
          this.holidayMap.set(d.calendarDate, d)
        })
      })
      if (!this.productFormData.products) {
        const startDate = new Date(currentDate.getFullYear() - 2, 0, 1)
        const endDate = new Date(currentDate.getFullYear() + 2, 11, 31)
        this.getCalendarByRange(startDate, endDate).then(data => {
          data.forEach(d => {
            this.holidayMap.set(d.calendarDate, d)
          })
        })
      }
    },
    handlePriceInfoChange (val, index, name, parent) {
      this.productFormData.products[index][name] = val
    },
    handleReferencePriceChange (val, index, parent) {
      this.productFormData.products[index].referencePrice = val
    },
    handlePriceTypeSelect (val, row, field, index) {
      if (val === ORDER_PRICE_TYPE.FORMULA) {
        this.$refs.productTable.toggleRowExpansion(row, true)
      } else {
        this.productFormData.products[index] = { ...this.productFormData.products[index], ...this.productPrice }
        this.$refs.productTable.toggleRowExpansion(row, false)
        this.resetProductPriceWatcher = !this.resetProductPriceWatcher
      }
    },
    getCurrencies () {
      return new Promise(resolve => {
        this.$request.get({
          url: this.$apis.sysCurrencies
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    getProducts () {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.products}`
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    getProductSpecifications () {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.productSpecifications}`
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    addNewProductLine () {
      this.productFormData.products.push(this.$_.cloneDeep(this.productAndPriceLine))
    },
    removeProduct (index) {
      this.productFormData.products.splice(index, 1)
    },
    getProductFormData () {
      let isValid
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          isValid = true
        } else {
          isValid = false
          return null
        }
      })
    const defaultProductSpec = this.productSpecificationList.find(spec=>spec.name == "-" )

      this.productFormData.products.forEach((product) => {
        product.sysProductSpecificationId??=defaultProductSpec?.id
      })
      if (isValid) {
        return this.productFormData.products
      }
    }
  }

}
</script>
<style scoped lang="scss">
// as same as @import "../../../order/index.scss";
.div-product-other-costs-info-body {
    >h4 {
      justify-self: left;
      margin: 0;
      margin-top: 20px;
    }
    .h4-btm {
      margin-top: 40px;
    }
    .el-table {
      width: 100%;
      .h5-el-table-col-index {
          color: #909399;
          margin: 0;
          margin-bottom: 20px;
          text-align: center;
      }
    }
    .el-button {
      margin-right: 20px;
      width: fit-content;
      justify-self: right;
      margin-bottom: 10px;
      float:right; // added for this table
    }
}
.div-table-col-product-pricing {
    text-align: left;
    >p {
        color: #0F376A;
        font-weight: 500;
        font-size: 16px;
        margin: 10px 0;
    }
    >div {
        display: flex;
        .el-form-item {
            margin-right: 10px;
        }
        .el-form-item-price-premium {
            margin-right: 10px;
            display: grid;
            text-align-last: left;
            .div-price-premium {
                display: flex;
                width: 260px;
                .el-select.unit-metric {
                    width: 150px
                }
            }
        }
        .el-checkbox {
            padding-top: 40px;
        }
    }
}

/deep/.el-table__expanded-cell{
  padding: 20px;
}
</style>
